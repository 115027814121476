var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-overview" },
    [
      _c("div"),
      _c(
        "div",
        { staticClass: "refresh-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.dataDialogVisible = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("统计说明")
            ]
          ),
          _c("div", { staticStyle: { margin: "0 20px" } }, [
            _vm._v("更新时间：" + _vm._s(_vm.time))
          ]),
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.refresh()
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("刷新")
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("门锁统计（已绑定）")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/pingtaisuo.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.platformLockCount))
                ]),
                _c("div", [_vm._v("平台锁")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", { attrs: { src: require("../../assets/guisuo.png") } }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.cabinetLockCount))
                ]),
                _c("div", [_vm._v("柜锁")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/gerensuo.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.personalLockCount))
                ]),
                _c("div", [_vm._v("个人锁")])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("门禁设备（R20）")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/menjing.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.accessControlTotal))
                ]),
                _c("div", [_vm._v("门禁总数")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/zaixianmenjing.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.onlineAccessControlCount))
                ]),
                _c("div", [_vm._v("在线门禁")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/lixianmengjing.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.offlineAccessControlCount))
                ]),
                _c("div", [_vm._v("离线门禁")])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("网关设备")]),
          _c("div", { staticClass: "content2" }, [
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.bluetoothGatewayL2GTotal))
                ]),
                _c("div", [_vm._v("蓝牙网关L2G")]),
                _c("div", [
                  _vm._v(
                    "在线：" +
                      _vm._s(_vm.bluetoothGatewayL2GOnline) +
                      "离线：" +
                      _vm._s(_vm.bluetoothGatewayL2GOffline)
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.bluetoothGatewayG4Total))
                ]),
                _c("div", [_vm._v("蓝牙网关G4")]),
                _c("div", [
                  _vm._v(
                    "在线：" +
                      _vm._s(_vm.bluetoothGatewayG4Online) +
                      "离线：" +
                      _vm._s(_vm.bluetoothGatewayG4Offline)
                  )
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("智能门禁")]),
          _c("div", { staticClass: "content2" }, [
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.w7Total))
                ]),
                _c("div", [_vm._v("分离式门禁设备W7系列")]),
                _c("div", [
                  _vm._v(
                    "在线：" +
                      _vm._s(_vm.w7Online) +
                      "离线：" +
                      _vm._s(_vm.w7Offline)
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.l2PlusTotal))
                ]),
                _c("div", [_vm._v("电子门禁L2+")]),
                _c("div", [
                  _vm._v(
                    "在线：" +
                      _vm._s(_vm.l2PlusOnline) +
                      "离线：" +
                      _vm._s(_vm.l2PlusOffline)
                  )
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("蓝牙公钥")]),
          _c("div", { staticClass: "content2" }, [
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.bluetoothPublicKeyTotal))
                ]),
                _c("div", [_vm._v("蓝牙公钥（总）")])
              ]
            ),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.bluetoothPublicKeyNotBorrowed))
                ]),
                _c("div", [_vm._v("未借出")])
              ]
            ),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              {
                staticClass: "data-num",
                staticStyle: { "text-align": "left" }
              },
              [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.bluetoothPublicKeyBorrowed))
                ]),
                _c("div", [_vm._v("已借出")])
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("平台锁城市分布情况")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "city-echart" }
            })
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("平台锁设备型号分类占比")
            ]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "400px" },
              attrs: { id: "total-orders-chart" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("易柜统计")]),
          _c("div", { staticClass: "cabinet-content" }, [
            _c("div", { staticClass: "count-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.yunDataCount))
              ]),
              _c("div", [_vm._v("云数平台易柜")]),
              _c("div", [_vm._v("安装绑定总数")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "count-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.mallCount))
              ]),
              _c("div", [_vm._v("商城平台易柜")]),
              _c("div", [_vm._v("安装绑定总数")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "count-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.l25Count))
              ]),
              _c("div", [_vm._v("云数平台L25(售货)")]),
              _c("div", [_vm._v("安装绑定总数")])
            ])
          ]),
          _c("div", { staticClass: "line-x" }),
          _c(
            "div",
            {
              staticClass: "cabinet-content",
              staticStyle: { "padding-bottom": "10px" }
            },
            [
              _c("div", { staticClass: "count-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.l25BCount))
                ]),
                _c("div", [_vm._v("云数平台L25B(售货)")]),
                _c("div", [_vm._v("安装绑定总数")])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "count-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.l27NCount))
                ]),
                _c("div", [_vm._v("云数旋转柜")]),
                _c("div", [_vm._v("L27/L27N")]),
                _c("div", [_vm._v("安装绑定总数")])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "count-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.t160AndT254Count))
                ]),
                _c("div", [_vm._v("云数弹簧机")]),
                _c("div", [_vm._v("T160/T254")]),
                _c("div", [_vm._v("安装绑定总数")])
              ])
            ]
          )
        ]),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("易柜安装城市分布")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "counter-echart" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("平台门锁动态")]),
          _c(
            "div",
            { staticClass: "table-content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    },
                    {
                      name: "el-table-infinite-scroll",
                      rawName: "v-el-table-infinite-scroll",
                      value: _vm.loadMore,
                      expression: "loadMore"
                    }
                  ],
                  staticStyle: { width: "90%", overflow: "auto" },
                  attrs: {
                    data: _vm.lockData,
                    height: "300",
                    "element-loading-text": "Loading"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdDate",
                      label: "日期",
                      width: "190",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "工程人员",
                      width: "120",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "typeName",
                      label: "门锁型号",
                      width: "100",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "customerLog", label: "动态", align: "left" }
                  }),
                  _c("div", {
                    staticClass: "empty",
                    attrs: { slot: "empty" },
                    slot: "empty"
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "10px",
                        "font-size": "14px",
                        color: "#ccc"
                      },
                      attrs: { slot: "append" },
                      slot: "append"
                    },
                    [
                      _vm.busy
                        ? _c("p", [
                            _c("i", {
                              staticClass: "el-icon-loading",
                              staticStyle: { "margin-right": "10px" }
                            }),
                            _vm._v("加载中···")
                          ])
                        : _vm._e(),
                      _vm.noMore ? _c("div", [_vm._v("没有更多了")]) : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("易柜动态")]),
          _c(
            "div",
            { staticClass: "table-content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.counterListLoading,
                      expression: "counterListLoading"
                    },
                    {
                      name: "el-table-infinite-scroll",
                      rawName: "v-el-table-infinite-scroll",
                      value: _vm.counterLoadMore,
                      expression: "counterLoadMore"
                    }
                  ],
                  staticStyle: { width: "90%" },
                  attrs: {
                    data: _vm.tableData,
                    height: "300",
                    "element-loading-text": "counterLoading"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdDate",
                      label: "日期",
                      width: "190",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "工程人员",
                      width: "120",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "counterName",
                      label: "易柜型号",
                      width: "100",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "customerLog", label: "动态", align: "left" }
                  }),
                  _c("div", {
                    staticClass: "empty",
                    attrs: { slot: "empty" },
                    slot: "empty"
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "10px",
                        "font-size": "14px",
                        color: "#ccc"
                      },
                      attrs: { slot: "append" },
                      slot: "append"
                    },
                    [
                      _vm.counterBusy
                        ? _c("p", [
                            _c("i", {
                              staticClass: "el-icon-loading",
                              staticStyle: { "margin-right": "10px" }
                            }),
                            _vm._v("加载中···")
                          ])
                        : _vm._e(),
                      _vm.counterNoMore
                        ? _c("div", [_vm._v("没有更多了")])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "统计说明",
            top: "2vh",
            visible: _vm.dataDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dataDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "25px", "margin-top": "-20px" } },
              [
                _c("div", { staticClass: "data-title" }, [
                  _vm._v("门锁统计（已绑定）")
                ]),
                _vm._v(
                  "\n                平台锁=工程软件绑定到套间的门锁，且当前处于已绑定状态；"
                ),
                _c("br"),
                _vm._v("\n                柜锁=用户绑定的柜锁总数；"),
                _c("br"),
                _vm._v(
                  "\n                个人锁=用户绑定到智家模块下的个人门锁总数；"
                ),
                _c("br")
              ]
            ),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("门禁设备（R20）")
              ]),
              _vm._v(
                "\n                门禁总数=R20上报到云数平台的门禁总数（包含在线和离线）;"
              ),
              _c("br"),
              _vm._v("\n                在线门禁=当前在线的R20门禁总数;"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("网关设备")]),
              _vm._v(
                "\n                统计云数平台网关设备总数，在线总数和离线总数；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("智能门禁")]),
              _vm._v(
                "\n                统计云数平台分离式设备W7/W8总数，在线总数和离线总数；"
              ),
              _c("br"),
              _vm._v("\n                电子门禁L2+总数，在线总数和离线总数；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("蓝牙公钥")]),
              _vm._v(
                "\n                统计云数平台蓝牙公钥总数，未借出总数和已借出总数；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("平台锁城市分布情况")
              ]),
              _vm._v(
                "\n                按照房源所在城市，统计已绑锁的房源城市分布情况；"
              ),
              _c("br"),
              _vm._v(
                "\n                按照从多到少，展示前10个城市的门锁所在城市数量；"
              ),
              _c("br"),
              _vm._v(
                "\n                非前10个城市的，均统计为“其他”并展示在最底部；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("平台锁设备型号分类占比")
              ]),
              _vm._v(
                "\n                平台锁按照锁的产品型号统计数量，以饼状图的形式进行统计分析；"
              ),
              _c("br"),
              _vm._v("\n                展示平台锁所有型号锁的数量；"),
              _c("br"),
              _vm._v("\n                展示已绑定的平台锁数量；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("易柜统计")]),
              _vm._v(
                "\n                云数平台易柜安装绑定总数=工程软件绑定易柜，选择绑定到云数平台的易柜总数；（统计当前已绑定的所有货柜类型）；"
              ),
              _c("br"),
              _vm._v(
                "\n                商城平台易柜安装绑定总数=工程软件绑定易柜，选择绑定到威富商城平台的易柜总数；（统计当前已绑定的所有货柜类型）；"
              ),
              _c("br"),
              _vm._v(
                "\n                云数平台L25(售货)安装绑定总数=当前云数平台已绑定的L25(售货)总数；"
              ),
              _c("br"),
              _vm._v(
                "\n                云数平台L25B(售货)安装绑定总数=当前云数平台已绑定的L25B(售货)总数；"
              ),
              _c("br"),
              _vm._v(
                "\n                云数旋转柜L27/L27N安装绑定总数=当前云数平台旋转柜L27+L27N安装绑定的总数；"
              ),
              _c("br"),
              _vm._v(
                "\n                云数弹簧机T160/T254安装绑定总数=当前云数平台弹簧机T160+T254安装绑定总数；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("易柜安装城市分布")
              ]),
              _vm._v(
                "\n                按照城市统计易柜总数，易柜安装的门店所在位置的城市；"
              ),
              _c("br"),
              _vm._v(
                "\n                从多到少统计前10个城市，不足10个城市则展示全部；"
              ),
              _c("br"),
              _vm._v(
                "\n                超过10个城市则展示前10个城市，其他城市则展示为其他；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("平台门锁动态")
              ]),
              _vm._v(
                "\n                工程人员绑定门锁和解绑门锁的操作动态；"
              ),
              _c("br"),
              _vm._v(
                "\n                展示日期，工程人员，门锁型号以及操作动作；"
              ),
              _c("br"),
              _vm._v(
                "\n                按照时间倒叙展示，最新操作动态展示在列表最上方；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("易柜动态")]),
              _vm._v(
                "\n                工程人员绑定易柜和解绑易柜的操作动态；"
              ),
              _c("br"),
              _vm._v(
                "\n                展示操作日期，工程人员，易柜操作动态信息；"
              ),
              _c("br"),
              _vm._v(
                "\n                按照操作时间倒叙展示，最新操作动态展示在列表最上方；"
              ),
              _c("br")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }