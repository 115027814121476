<template>
    <div class="data-overview">
        <div></div>
        <div class="refresh-box">
            <el-button type="text" class="refresh-btn" @click="dataDialogVisible=true"><i class="el-icon-question" style="margin-right:2px"></i>统计说明</el-button>
            <div style="margin:0 20px">更新时间：{{time}}</div>
            <el-button type="text" class="refresh-btn" @click="refresh()"><i class="el-icon-refresh" style="margin-right:2px"></i>刷新</el-button>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">门锁统计（已绑定）</div>
                <div class="content">
                    <div class="module-box">
                        <img src="../../assets/pingtaisuo.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{platformLockCount}}</div>
                            <div>平台锁</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/guisuo.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{cabinetLockCount}}</div>
                            <div>柜锁</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/gerensuo.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{personalLockCount}}</div>
                            <div>个人锁</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">门禁设备（R20）</div>
                <div class="content">
                    <div class="module-box">
                        <img src="../../assets/menjing.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{accessControlTotal}}</div>
                            <div>门禁总数</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/zaixianmenjing.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{onlineAccessControlCount}}</div>
                            <div>在线门禁</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/lixianmengjing.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{offlineAccessControlCount}}</div>
                            <div>离线门禁</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">网关设备</div>
                <div class="content2">
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{bluetoothGatewayL2GTotal}}</div>
                        <div>蓝牙网关L2G</div>
                        <div>在线：{{bluetoothGatewayL2GOnline}}离线：{{bluetoothGatewayL2GOffline}}</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{bluetoothGatewayG4Total}}</div>
                        <div>蓝牙网关G4</div>
                        <div>在线：{{bluetoothGatewayG4Online}}离线：{{bluetoothGatewayG4Offline}}</div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">智能门禁</div>
                <div class="content2">
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{w7Total}}</div>
                        <div>分离式门禁设备W7系列</div>
                        <div>在线：{{w7Online}}离线：{{w7Offline}}</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{l2PlusTotal}}</div>
                        <div>电子门禁L2+</div>
                        <div>在线：{{l2PlusOnline}}离线：{{l2PlusOffline}}</div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">蓝牙公钥</div>
                <div class="content2">
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{bluetoothPublicKeyTotal}}</div>
                        <div>蓝牙公钥（总）</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{bluetoothPublicKeyNotBorrowed}}</div>
                        <div>未借出</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num" style="text-align:left">
                        <div style="font-size:30px;">{{bluetoothPublicKeyBorrowed}}</div>
                        <div>已借出</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">平台锁城市分布情况</div>
                <div id="city-echart" class="module-content" :style="{ width: '784px', height: '322px'}"></div> 
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">平台锁设备型号分类占比</div>
                <div id="total-orders-chart" class="module-content" :style="{ width: '784px', height: '400px'}"></div>  
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">易柜统计</div>
                <div class="cabinet-content">
                    <div class="count-num">
                        <div style="font-size:30px;">{{yunDataCount}}</div>
                        <div>云数平台易柜</div>
                        <div>安装绑定总数</div>
                    </div>
                    <div class="line"></div>
                    <div class="count-num">
                        <div style="font-size:30px;">{{mallCount}}</div>
                        <div>商城平台易柜</div>
                        <div>安装绑定总数</div>
                    </div>
                    <div class="line"></div>
                    <div class="count-num">
                        <div style="font-size:30px;">{{l25Count}}</div>
                        <div>云数平台L25(售货)</div>
                        <div>安装绑定总数</div>
                    </div>
                </div>
                <div class="line-x"></div>
                <div class="cabinet-content" style="padding-bottom:10px">
                    <div class="count-num">
                        <div style="font-size:30px;">{{l25BCount}}</div>
                        <div>云数平台L25B(售货)</div>
                        <div>安装绑定总数</div>
                    </div>
                    <div class="line"></div>
                    <div class="count-num">
                        <div style="font-size:30px;">{{l27NCount}}</div>
                        <div>云数旋转柜</div>
                        <div>L27/L27N</div>
                        <div>安装绑定总数</div>
                    </div>
                    <div class="line"></div>
                    <div class="count-num">
                        <div style="font-size:30px;">{{t160AndT254Count}}</div>
                        <div>云数弹簧机</div>
                        <div>T160/T254</div>
                        <div>安装绑定总数</div>
                    </div>
                </div>
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">易柜安装城市分布</div>
                <div id="counter-echart" class="module-content" :style="{ width: '784px', height: '322px'}"></div> 
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">平台门锁动态</div>
                <div class="table-content">
                    <el-table :data="lockData" style="width: 90%;overflow:auto" height="300"
                    v-loading="listLoading" element-loading-text="Loading" v-el-table-infinite-scroll="loadMore">
                        <el-table-column prop="createdDate" label="日期" width="190" align="left"></el-table-column>
                        <el-table-column prop="realName" label="工程人员" width="120" align="left"></el-table-column>
                        <el-table-column prop="typeName" label="门锁型号" width="100" align="left"></el-table-column>
                        <el-table-column prop="customerLog" label="动态" align="left"></el-table-column>
                        <div slot="empty" class="empty"/>
                        <div slot="append" style="text-align: center;padding:10px;font-size: 14px;color: #ccc;">
                            <p v-if="busy"><i class="el-icon-loading" style="margin-right: 10px;" />加载中···</p>
                            <div v-if="noMore">没有更多了</div>
                        </div>
                    </el-table>
                </div>
            </div>
            <div class="module-content">
                <div class="title">易柜动态</div>
                <div class="table-content">
                    <el-table :data="tableData" style="width: 90%;" height="300"
                    v-loading="counterListLoading" element-loading-text="counterLoading" v-el-table-infinite-scroll="counterLoadMore">
                        <el-table-column prop="createdDate" label="日期" width="190" align="left"></el-table-column>
                        <el-table-column prop="realName" label="工程人员" width="120" align="left"></el-table-column>
                        <el-table-column prop="counterName" label="易柜型号" width="100" align="left"></el-table-column>
                        <el-table-column prop="customerLog" label="动态" align="left"></el-table-column>
                        <div slot="empty" class="empty"/>
                        <div slot="append" style="text-align: center;padding:10px;font-size: 14px;color: #ccc;">
                            <p v-if="counterBusy"><i class="el-icon-loading" style="margin-right: 10px;" />加载中···</p>
                            <div v-if="counterNoMore">没有更多了</div>
                        </div>
                    </el-table>
                </div>
            </div>  
        </div>
        <el-dialog title="统计说明" top="2vh" :visible.sync="dataDialogVisible"  :close-on-click-modal="false">
            <div>
                <div style="line-height:25px;margin-top:-20px">
                    <div class="data-title">门锁统计（已绑定）</div>
                    平台锁=工程软件绑定到套间的门锁，且当前处于已绑定状态；<br>
                    柜锁=用户绑定的柜锁总数；<br>
                    个人锁=用户绑定到智家模块下的个人门锁总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">门禁设备（R20）</div>
                    门禁总数=R20上报到云数平台的门禁总数（包含在线和离线）;<br>
                    在线门禁=当前在线的R20门禁总数;<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">网关设备</div>
                    统计云数平台网关设备总数，在线总数和离线总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">智能门禁</div>
                    统计云数平台分离式设备W7/W8总数，在线总数和离线总数；<br>
                    电子门禁L2+总数，在线总数和离线总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">蓝牙公钥</div>
                    统计云数平台蓝牙公钥总数，未借出总数和已借出总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">平台锁城市分布情况</div>
                    按照房源所在城市，统计已绑锁的房源城市分布情况；<br>
                    按照从多到少，展示前10个城市的门锁所在城市数量；<br>
                    非前10个城市的，均统计为“其他”并展示在最底部；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">平台锁设备型号分类占比</div>
                    平台锁按照锁的产品型号统计数量，以饼状图的形式进行统计分析；<br>
                    展示平台锁所有型号锁的数量；<br>
                    展示已绑定的平台锁数量；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">易柜统计</div>
                    云数平台易柜安装绑定总数=工程软件绑定易柜，选择绑定到云数平台的易柜总数；（统计当前已绑定的所有货柜类型）；<br>
                    商城平台易柜安装绑定总数=工程软件绑定易柜，选择绑定到威富商城平台的易柜总数；（统计当前已绑定的所有货柜类型）；<br>
                    云数平台L25(售货)安装绑定总数=当前云数平台已绑定的L25(售货)总数；<br>
                    云数平台L25B(售货)安装绑定总数=当前云数平台已绑定的L25B(售货)总数；<br>
                    云数旋转柜L27/L27N安装绑定总数=当前云数平台旋转柜L27+L27N安装绑定的总数；<br>
                    云数弹簧机T160/T254安装绑定总数=当前云数平台弹簧机T160+T254安装绑定总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">易柜安装城市分布</div>
                    按照城市统计易柜总数，易柜安装的门店所在位置的城市；<br>
                    从多到少统计前10个城市，不足10个城市则展示全部；<br>
                    超过10个城市则展示前10个城市，其他城市则展示为其他；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">平台门锁动态</div>
                    工程人员绑定门锁和解绑门锁的操作动态；<br>
                    展示日期，工程人员，门锁型号以及操作动作；<br>
                    按照时间倒叙展示，最新操作动态展示在列表最上方；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">易柜动态</div>
                    工程人员绑定易柜和解绑易柜的操作动态；<br>
                    展示操作日期，工程人员，易柜操作动态信息；<br>
                    按照操作时间倒叙展示，最新操作动态展示在列表最上方；<br>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
    directives: {
        'el-table-infinite-scroll': elTableInfiniteScroll
    },
    data() {
        return {
            time: '',
            platformLockCount:'',
            cabinetLockCount:'',
            personalLockCount:'',
            gatewayDeviceCount:'',
            accessControlTotal:'',
            onlineAccessControlCount:'',
            offlineAccessControlCount:'',
            w7Total:'',
            w7Online:'',
            w7Offline:'',
            l2PlusTotal:'',
            l2PlusOnline:'',
            l2PlusOffline:'',
            bluetoothGatewayG4Total:'',
            bluetoothGatewayG4Online:'',
            bluetoothGatewayG4Offline:'',
            bluetoothGatewayL2GTotal:'',
            bluetoothGatewayL2GOnline:'',
            bluetoothGatewayL2GOffline:'',
            bluetoothPublicKeyTotal:'',
            bluetoothPublicKeyNotBorrowed:'',
            bluetoothPublicKeyBorrowed:'',
            yunDataCount:'',
            mallCount:'',
            l25Count:'',
            l25BCount:'',
            l27NCount:'',
            t160AndT254Count:'',
            pieData: [],
            dataDialogVisible: false,
            lockData:[],
            listLoading: true,
            pageSize: 20,
            pageNum: 1,
            total:0,
            busy:false,
            noMore:false,
            tableData: [],
            counterListLoading: true,
            counterpageSize: 20,
            counterpageNum: 1,
            countertotal:0,
            counterBusy:false,
            counterNoMore:false,
            cityName: [],
            cityData: [],
            counterName: [],
            counterData: [],
        }
    },
    activated() {
        this.time = this.getNowTime();
        this.getData();
        this.lockData = [];
        this.tableData = [];
        this.listLoading = true;
        this.pageSize = 20;
        this.pageNum = 1;
        this.total = 0,
        this.busy = false;
        this.noMore = false;
        this.counterListLoading = true;
        this.counterpageSize = 20;
        this.counterpageNum = 1;
        this.countertotal = 0,
        this.counterBusy = false;
        this.counterNoMore = false;
        this.getLockList();
        this.getCounterList();
    },
    mounted() {
    },
    methods: {
        getData(){
            this.post("statistic-service/statistic/getDeviceStatistics").then(res=>{
                if(res){
                    this.platformLockCount = res.platformLockCount!=null?res.platformLockCount:'--';
                    this.cabinetLockCount = res.cabinetLockCount!=null?res.cabinetLockCount:'--';
                    this.personalLockCount = res.personalLockCount!=null?res.personalLockCount:'--';
                    this.gatewayDeviceCount = res.gatewayDeviceCount!=null?res.gatewayDeviceCount:'--';
                    this.accessControlTotal = res.accessControlTotal!=null?res.accessControlTotal:'--';
                    this.onlineAccessControlCount = res.onlineAccessControlCount!=null?res.onlineAccessControlCount:'--';
                    this.offlineAccessControlCount = res.offlineAccessControlCount!=null?res.offlineAccessControlCount:'--';
                    this.w7Total = res.w7Total!=null?res.w7Total:'--';
                    this.w7Online = res.w7Online!=null?res.w7Online:'--';
                    this.w7Offline = res.w7Offline!=null?res.w7Offline:'--';
                    this.l2PlusTotal = res.l2PlusTotal!=null?res.l2PlusTotal:'--';
                    this.l2PlusOnline = res.l2PlusOnline!=null?res.l2PlusOnline:'--';
                    this.l2PlusOffline = res.l2PlusOffline!=null?res.l2PlusOffline:'--';
                    this.bluetoothGatewayG4Total = res.bluetoothGatewayG4Total!=null?res.bluetoothGatewayG4Total:'--';
                    this.bluetoothGatewayG4Online = res.bluetoothGatewayG4Online!=null?res.bluetoothGatewayG4Online:'--';
                    this.bluetoothGatewayG4Offline = res.bluetoothGatewayG4Offline!=null?res.bluetoothGatewayG4Offline:'--';
                    this.bluetoothGatewayL2GTotal = res.bluetoothGatewayL2GTotal!=null?res.bluetoothGatewayL2GTotal:'--';
                    this.bluetoothGatewayL2GOnline = res.bluetoothGatewayL2GOnline!=null?res.bluetoothGatewayL2GOnline:'--';
                    this.bluetoothGatewayL2GOffline = res.bluetoothGatewayL2GOffline!=null?res.bluetoothGatewayL2GOffline:'--';
                    this.bluetoothPublicKeyTotal = res.bluetoothPublicKeyTotal!=null?res.bluetoothPublicKeyTotal:'--';
                    this.bluetoothPublicKeyNotBorrowed = res.bluetoothPublicKeyNotBorrowed!=null?res.bluetoothPublicKeyNotBorrowed:'--';
                    this.bluetoothPublicKeyBorrowed = res.bluetoothPublicKeyBorrowed!=null?res.bluetoothPublicKeyBorrowed:'--';
                    this.yunDataCount = res.yunDataCount!=null?res.yunDataCount:'--';
                    this.mallCount = res.mallCount!=null?res.mallCount:'--';
                    this.l25Count = res.l25Count!=null?res.l25Count:'--';
                    this.l25BCount = res.l25BCount!=null?res.l25BCount:'--';
                    this.l27NCount = res.l27NCount!=null?res.l27NCount:'--';
                    this.t160AndT254Count = res.t160AndT254Count!=null?res.t160AndT254Count:'--';
                    let lockTypeData = [];
                    res.typeCounts.forEach(item =>{
                        if (item.type == '1003') {
                            item.typeName = 'WF-C1';
                        } else if (item.type == '1004') {
                            item.typeName = 'WF-C1Z';
                        } else if (item.type == '1008') {
                            item.typeName = 'WF-X11MG';
                        } else if (item.type == '1011') {
                            item.typeName = 'WF-C2Z';
                        } else if (item.type == '1009') {
                            item.typeName = 'WF-S64G';
                        } else if (item.type == '2003') {
                            item.typeName = 'WF-S53';
                        } else if (item.type == '2002') {
                            item.typeName = 'WF-X4M';
                        } else {
                            item.typeName = this.lockTypeArr[item.type];
                        }
                        lockTypeData.push({
                            value:item.count,
                            name:item.typeName
                        })
                    })
                    this.pieData = lockTypeData;
                    this.creatEchart();
                     // 平台锁城市
                    let nameData = [];
                    let numberData = [];
                    res.cityCounts.forEach(item =>{
                        nameData.push(item.city);
                        numberData.push(item.count)
                    });
                    this.cityName = nameData
                    this.cityData = numberData;
                    this.creatCity();

                    // 易柜安装城市
                    let counterNameData = [];
                    let counterNumberData = [];
                    res.cityCounters.forEach(item =>{
                        counterNameData.push(item.city);
                        counterNumberData.push(item.count)
                    });
                    this.counterName = counterNameData
                    this.counterData = counterNumberData;
                    this.creatCounter();
                }
            })
        },
        loadMore() {
            if (this.busy || this.total < 20) return //每页展示100条，总数小于100或加载中时不加载
            const total = this.pageSize * this.pageNum
            if (this.total < total) { // 总数小于已请求页数时不加载，提示noMore'没有更多了'
                this.noMore = true
                return
            }
            this.busy = true
            this.pageNum += 1
            this.getLockList()
        },
        counterLoadMore() {
            if (this.counterBusy || this.countertotal < 20) return //每页展示100条，总数小于100或加载中时不加载
            const total = this.counterpageSize * this.counterpageNum
            if (this.countertotal < total) { // 总数小于已请求页数时不加载，提示noMore'没有更多了'
                this.counterNoMore = true
                return
            }
            this.counterBusy = true
            this.counterpageNum += 1
            this.getCounterList()
        },
        getNowTime(){
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
            var week = weekArr[date.getDay()];
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return year + "/" + month + "/" + day + " " + hour + sign2 + minutes + sign2 + seconds;
        },
        refresh(){
            this.time = this.getNowTime();
            this.getData();
            this.lockData = [];
            this.tableData = [];
            this.listLoading = true;
            this.pageSize = 20;
            this.pageNum = 1;
            this.total = 0,
            this.busy = false;
            this.noMore = false;
            this.counterListLoading = true;
            this.counterpageSize = 20;
            this.counterpageNum = 1;
            this.countertotal = 0,
            this.counterBusy = false;
            this.counterNoMore = false;
            this.getLockList();
            this.getCounterList();
        },
        creatEchart(){
            const chartDom = document.getElementById('total-orders-chart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    itemWidth: 12,   // 设置图例图形的宽
                    itemHeight: 12,  // 设置图例图形的高
                    // 设置滚动效果
                    type: 'scroll',
                },
                // color: ['#629AFF', '#F49A7E', '#9C8EFE', '#69CABE', '#FEE355'],
                series: [
                    {
                        name: '房源类型',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        data: this.pieData,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatCity(){
            const chartDom = document.getElementById('city-echart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                //图表位置
                grid: {
                    left: "3%",
                    // right: "5%",
                    bottom: "3%",
                    top:'15px',
                    containLabel: true,
                },
                //X轴
                xAxis: {
                    type: "value",
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //不显示X轴刻度线和数字
                    splitLine: { show: false },
                    axisLabel: { show: false },
                },
                yAxis: {
                    type: "category",
                    data: this.cityName,
                    //升序
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //key和图间距
                    offset: 10,
                    //key文字大小
                    nameTextStyle: {
                        fontSize: 16,
                    },
                },
                color: ['#76DC71'],
                series: [
                {
                    //柱状图自动排序，排序自动让Y轴名字跟着数据动
                    realtimeSort: false,
                    name: "数量",
                    type: "bar",
                    data: this.cityData,
                    barWidth: 12,
                    barGap: 20,
                    smooth: false,
                    valueAnimation: false,
                    //Y轴数字显示部分
                    label: {
                        normal: {
                            show: true,
                            position: "right",
                            valueAnimation: false,
                            offset: [5, 0],
                            textStyle: {
                                color: "#333",
                                fontSize: 14,
                            },
                        },
                    },
                    itemStyle: {
                        emphasis: {
                            barBorderRadius: 7,
                        },
                        //颜色样式部分
                        normal: {
                            barBorderRadius: 7,
                        },
                    },
                },
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatCounter(){
            const chartDom = document.getElementById('counter-echart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                //图表位置
                grid: {
                    left: "3%",
                    // right: "5%",
                    bottom: "3%",
                    top:'15px',
                    containLabel: true,
                },
                //X轴
                xAxis: {
                    type: "value",
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //不显示X轴刻度线和数字
                    splitLine: { show: false },
                    axisLabel: { show: false },
                },
                yAxis: {
                    type: "category",
                    data: this.counterName,
                    //升序
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //key和图间距
                    offset: 10,
                    //key文字大小
                    nameTextStyle: {
                        fontSize: 16,
                    },
                },
                color: ['#76DC71'],
                series: [
                {
                    //柱状图自动排序，排序自动让Y轴名字跟着数据动
                    realtimeSort: false,
                    name: "数量",
                    type: "bar",
                    data: this.counterData,
                    barWidth: 12,
                    barGap: 20,
                    smooth: false,
                    valueAnimation: false,
                    //Y轴数字显示部分
                    label: {
                        normal: {
                            show: true,
                            position: "right",
                            valueAnimation: false,
                            offset: [5, 0],
                            textStyle: {
                                color: "#333",
                                fontSize: 14,
                            },
                        },
                    },
                    itemStyle: {
                        emphasis: {
                            barBorderRadius: 7,
                        },
                        //颜色样式部分
                        normal: {
                            barBorderRadius: 7,
                        },
                    },
                },
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        getLockList(){
            this.listLoading = true;
            let dto = {
                pageSize: this.pageSize,
                pageNum: this.pageNum
            }
            this.post("statistic-service/device-log/getLockLog",dto,{
                isUseResponse: true,
            }).then(res=>{
                if(res.data.code == 0){
                    res.data.list.forEach(item=>{
                        if (item.deviceType == '1003') {
                            item.typeName = 'WF-C1';
                        } else if (item.deviceType == '1004') {
                            item.typeName = 'WF-C1Z';
                        } else if (item.deviceType == '1008') {
                            item.typeName = 'WF-X11MG';
                        } else if (item.deviceType == '1011') {
                            item.typeName = 'WF-C2Z';
                        } else if (item.deviceType == '1009') {
                            item.typeName = 'WF-S64G';
                        } else if (item.deviceType == '2003') {
                            item.typeName = 'WF-S53';
                        } else if (item.deviceType == '2002') {
                            item.typeName = 'WF-X4M';
                        } else {
                            item.typeName = this.lockTypeArr[item.deviceType];
                        }
                    })
                    this.lockData = this.lockData.concat(res.data.list);
                    this.total = this.lockData.length;
                    this.listLoading = false;
                    this.busy = false;
                }
            })
        },
        getCounterList(){
            this.counterListLoading = true;
            let dto = {
                pageSize: this.counterpageSize,
                pageNum: this.counterpageNum
            }
            this.post("statistic-service/device-log/getCounterLog",dto,{
                isUseResponse: true,
            }).then(res=>{
                res.data.list.forEach(item=>{
                    if ([6, 7,2001,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013].indexOf(item.deviceType) != -1) {
                        if (item.deviceType == 6) {
                            // if (item.size == 1) {
                                item.counterName = 'WF-L21BD';
                            // } else if (item.size == 2) {
                            //     item.counterName = 'WF-L21BX';
                            // }
                        }
                        if (item.deviceType == 7) {
                            // if (item.size == 1) {
                                item.counterName = 'WF-L22BD';
                            // } else if (item.size == 2) {
                            //     item.counterName = 'WF-L22BX';
                            // }
                        }
                        if (item.deviceType == 2001) {
                            item.counterName = 'WF-L27';
                        }
                        if (item.deviceType == 2004) {
                            item.counterName = 'WF-L25E';
                        }
                        if (item.deviceType == 2005) {
                            item.counterName = 'WF-L25F';
                        }
                        if (item.deviceType == 2006) {
                            item.counterName = 'WF-L27N';
                        }
                        if (item.deviceType == 2007) {
                            item.counterName = 'WF-L25MK';
                        }
                        if (item.deviceType == 2008) {
                            item.counterName = 'WF-L25E85';
                        }
                        if (item.deviceType == 2009) {
                            item.counterName = 'WF-L25-6';
                        }
                        if (item.deviceType == 2010) {
                            item.counterName = 'WF-L25-12';
                        }
                        if (item.deviceType == 2011) {
                            item.counterName = 'WF-L25-18';
                        }
                        if (item.deviceType == 2012) {
                            item.counterName = 'WF-L25-24';
                        }
                        if (item.deviceType == 2013) {
                            item.counterName = 'WF-L25BMK';
                        }
                    } else {
                        item.counterName = 'WF-' + ['L2K', 'L21', 'L22', '', '', '', '', 'L25', 'L25A', 'L25B', 'L25C', 'L25AF', 'L25CF', 'L25AK', 'L25CK','L25BK','L25-18','L25D','L25AB','T160','T254'][item.deviceType - 1]
                    }
                })
                this.tableData = this.tableData.concat(res.data.list);
                this.countertotal = this.tableData.length;
                this.counterListLoading = false;
                this.counterBusy = false;
            })
        }
    }
}
</script>
<style lang="scss" scope>
.data-overview{
    background: #F6F6F6;
    margin: -10px -20px;
    padding: 35px;
    font-size: 14px;
    overflow: auto;
    .module{
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    .refresh-box{
        height: 16px;
        line-height: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .refresh-btn{
        color: #333;
        padding: 0;
        margin-left: 3px;
    }
    .title{
        border-left: 4px solid #30BAC1;
        height: 16px;
        line-height: 16px;
        padding-left: 7px;
        background: #FFFFFF;
        font-size: 16px;
    }
    .content{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
    }
    .content2{
        background: #FFFFFF;
        width: 520px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .cabinet-content{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
    }
    .count-num{
        text-align: center;
        width: 198px;
    }
    .line-x{
        background: #DCDFE6;
        width: 794px;
        height: 1px;
    }
    .line{
        background: #DCDFE6;
        width: 1px;
        height: 93px;
    }
    .module-box{
        display: flex; 
        img{
            width: 56px;
            height: 56px;
            margin-right: 15px;
        }
    }
    .module-content {
        padding: 10px 0 0 10px;
        background: #FFFFFF;
    }
    .data-title {
        font-weight: bold;
        margin: 10px 0 0;
    }
    .el-table__row>td{ border: none; }
    .el-table th.is-leaf { border-bottom: none; }
    .el-table::before { height: 0px; } 
    .table-content{
        overflow: auto;
        background: #FFFFFF;
        width: 794px;
        margin: 10px 0 30px;
        height: '400px';
    }
}
</style>